import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/Main.scss';
// import App from './App';
const App = lazy(() => import('./App')); 
 
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <App />  
    </Suspense>
  </React.StrictMode>     
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();